import Layout from '@/views/Layout/Index.vue'
import { RouteRecordRaw } from 'vue-router'

export default <RouteRecordRaw>{
  path: '/Burgeon',
  component: Layout,
  redirect: '/Home',
  name: 'Burgeon',
  meta: { title: '伯俊', icon: 'lock' },
  children: [
    {
      path: '/Burgeon/Customer',
      component: () => import(/* webpackChunkName: "NotifyAssistant" */ '@/views/Burgeon/Customer/Index.vue'),
      meta: { title: '会员管理', icon: 'el-icon-menu', roles: [] }
    },
    {
      path: '/Burgeon/Rank',
      component: () => import(/* webpackChunkName: "NotifyAssistant" */ '@/views/Burgeon/Rank/Index.vue'),
      meta: { title: '等级管理', icon: 'el-icon-user', roles: [] }
    }
  ]
}